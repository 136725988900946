<template>
  <div class="container add-board-column-modal-main-container">
    <h1 class="add-board-column-modal-main-title">Agregar columna a tablero</h1>
    <b-field
      label="Nombre"
      expanded
      label-position="inside"
      :type="error.name == true ? 'is-danger' : ''"
    >
      <b-input
        placeholder="Ejemplo: Tablero nuevo"
        icon="warehouse"
        v-model="newColumn.name"
      ></b-input>
    </b-field>
    <div class="add-board-column-controls">
      <b-button type="is-success" @click="addColumn()"
        >Guardar tablero</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "AddBoardColumnModal",
  props: ["boardInformation"],
  components: {},
  data() {
    return {
      newColumn: {
        name: "",
      },
      error: {
        name: false,
      },
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    addColumn() {
      if (this.newColumn.name.length > 0) {
        this.boardInformation.stages.push(this.newColumn.name);
        this.$store
          .dispatch("EDITTASKBOARDS", this.boardInformation)
          .then((response) => {
            if (response == "Success") {
              this.newColumn = {
                name: "",
              };
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se guardó exitosamente la nueva columna",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                onConfirm: () => {
                  this.closeModal();
                },
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al guardar la columna en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          });
      } else {
        if (this.newColumn.name <= 0) {
          this.error.name = true;
        }

        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Por favor valide que los datos son correctos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../style/AddBoardColumnModal.css";
@import "../../Global/style/Global.css";
</style>
