<template>
  <div class="container boards-main-container">
    <Navbar :showSearch="false"></Navbar>
    <div class="controls">
      <b-button
        type="is-primary"
        @click="openAddBoardColumn()"
        v-if="currentboard"
        >Agregar columna</b-button
      >
      <b-select
        placeholder="Selecciona un tablero"
        @input="changeBoard"
        v-model="selectedBoard"
      >
        <option value="" v-if="boards.length == 0">
          Selecciona un tablero
        </option>
        <option v-for="board in boards" :value="board" :key="board._id">
          {{ board.name }}
        </option>
        <option value="addBoard">Agregar tablero</option>
      </b-select>
      <b-button
        type="is-primary"
        @click="openAddTaskBoard()"
        class="bords-second-button"
        >Agregar tablero</b-button
      >
    </div>
    <div class="absolut-width boards-second-section-container">
      <kanban-board
        :stages="currentboard.stages"
        :blocks="currentboard.blocks"
        v-if="boards.length > 0"
        :key="componentKey"
      >
        <div
          v-for="stage in currentboard.stages"
          :slot="stage"
          :key="stage"
          class="Boards-header-container"
        >
          <h2>{{ stage }}</h2>
          <b-dropdown
            aria-role="list"
            class="is-pulled-right"
            position="is-bottom-left"
          >
            <b-icon icon="dots-vertical" slot="trigger"></b-icon>
            <b-dropdown-item aria-role="listitem">Borrar</b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          v-for="block in currentboard.blocks"
          :slot="block.id"
          :key="block.id"
          class="Boards-task-full-info"
        >
          <div class="Boards-task-top-info">
            <div>
              {{ block.id }}
            </div>
            <div>
              {{ block.title }}
            </div>
          </div>
          <div class="Boards-task-bottom-info">
            <div>
              {{ block.title }}
            </div>
          </div>
        </div>
      </kanban-board>
      <div class="boards-no-boards" v-if="boards.length == 0">
        <h2>Aun no tienes ningun tablero</h2>
        <b-button type="is-primary" @click="openAddTaskBoard()"
          >Agregar tablero</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../Global/components/Navbar";
import moment from "moment";
import AddBoardModal from "../components/AddBoardModal";
import AddBoardColumnModal from "../components/AddBoardColumnModal";
// @ is an alias to /src
export default {
  name: "Calendar",
  components: {
    Navbar,
  },
  data() {
    return {
      selectedBoard: "",
      componentKey: 0,
    };
  },
  mounted() {
    moment.locale("es");
  },
  methods: {
    openAddBoardColumn() {
      const modal = this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddBoardColumnModal,
        props: {
          boardInformation: this.currentboard,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
      modal.$on("close", () => this.forceRerender());
    },
    openAddTaskBoard() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddBoardModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    changeBoard(e) {
      if (e == "addBoard") {
        this.openAddTaskBoard();
        //Allog select to return to starting value
        setTimeout(() => {
          this.selectedBoard = "";
        }, 500);
      } else if (e._id) {
        for (let board of this.boards) {
          if (board._id === e._id) {
            this.currentboard = board;
          }
        }
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
  watch: {
    currentboard() {
      this.selectedBoard = this.currentboard;
    },
    boards() {
      this.selectedBoard = this.currentboard;
    },
  },
  computed: {
    boards() {
      let tempBoards = this.$store.getters.TASKBOARDS;
      if (tempBoards) {
        return tempBoards;
      } else {
        return [];
      }
    },
    currentboard() {
      return this.boards.length > 0 ? this.boards[0] : "";
    },
  },
};
</script>

<style scoped>
@import "../style/Boards.css";
@import "../../Global/style/Global.css";
</style>
