<template>
  <div class="container add-board-modal-main-container">
    <h1 class="add-board-modal-main-title">Agregar tablero</h1>
    <b-field
      label="Nombre"
      expanded
      label-position="inside"
      :type="error.name == true ? 'is-danger' : ''"
    >
      <b-input
        placeholder="Ejemplo: Pendientes"
        icon="warehouse"
        v-model="newBoard.name"
      ></b-input>
    </b-field>
    <b-field
      label="Descripción"
      expanded
      label-position="inside"
      :type="error.name == true ? 'is-danger' : ''"
    >
      <b-input
        placeholder="Ejemplo: Descripción de tablero uno"
        icon="warehouse"
        v-model="newBoard.description"
      ></b-input>
    </b-field>
    <div class="add-board-controls">
      <b-button type="is-success" @click="addNewBoard()"
        >Guardar columna</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "AddBoardModal",
  components: {},
  data() {
    return {
      newBoard: {
        name: "",
        description: "",
      },
      error: {
        name: false,
        description: false,
      },
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    addNewBoard() {
      if (
        this.newBoard.name.length > 0 &&
        this.newBoard.description.length > 0
      ) {
        this.$store
          .dispatch("SAVETASKBOARDS", this.newBoard)
          .then((response) => {
            if (response == "Success") {
              this.newBoard = {
                name: "",
                description: "",
              };
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se guardó exitosamente el nuevo tablero",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                onConfirm: () => {
                  this.closeModal();
                },
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al guardar el tablero en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          });
      } else {
        if (this.newBoard.name.length <= 0) {
          this.error.name = true;
        }

        if (this.newBoard.description.length <= 0) {
          this.error.description = true;
        }

        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Por favor valide que los datos son correctos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../style/AddBoardModal.css";
@import "../../Global/style/Global.css";
</style>
